<template>
	<div style="background: #f1f1f1;height:100vh;display: inline-block;width: 100%;">
		<el-container>
			<el-aside width="200px">
				<el-menu default-active="2" class="el-menu-vertical-demo"  @select="handleSelect" >
					<el-menu-item index="/index">
						<i class="el-icon-menu"></i>
						<span slot="title"> {{$t('newadd.t.会员中心')}}</span>
					</el-menu-item>
					<!-- <el-submenu index="/book">
						<template slot="title">
							<i class="el-icon-location"></i>
							<span> {{$t('vipCenter.menu.订单中心')}} </span>
						</template>
						<el-menu-item-group>
							 <template slot="title">{{$t('vipCenter.menu.订单中心')}}</template>
							<el-menu-item index="/book?id=1">{{$t('vipCenter.menu.已完成')}}</el-menu-item>
							<el-menu-item index="/book?id=2">{{$t('vipCenter.menu.待发货')}}</el-menu-item>
							<el-menu-item index="/book?id=3">{{$t('vipCenter.menu.待收货')}}</el-menu-item>
						</el-menu-item-group>
					</el-submenu> -->
					<el-menu-item index="/CustomDesign" >
						<i class="el-icon-menu"></i>
						<span slot="title">{{$t('newadd.t.自定义设计点')}}</span>
					</el-menu-item>
					<el-menu-item index="/preinstall">
						<i class="el-icon-setting"></i>
						<span slot="title">{{$t('newadd.t.单位预设')}}</span>
					</el-menu-item>
				<!-- 	<el-menu-item index="/ApplyInvoice" >
						<i class="el-icon-document"></i>
						<span slot="title">{{$t('vipCenter.menu.申请开发票')}}</span>
					</el-menu-item>
					<el-menu-item index="/serive">
						<i class="el-icon-setting"></i>
						<span slot="title">{{$t('vipCenter.menu.会员服务')}}</span>
					</el-menu-item> -->
				</el-menu>
			</el-aside>
			<el-main>
				<router-view></router-view>
			</el-main>

		</el-container>
	</div>
</template>
<script>
	export default {
		props: {
			active: {},
		},
		data() {
			return {


			}
		},
		mounted() {

		},
		  methods: {
		       handleSelect(key, keyPath) {
				   this.$router.push(key)
		       },
			   
		    }
	}
</script>

<style scoped>
	
	
	.el-container {
		width: 1200px;
		margin: 60px auto;
		height: 700px;
	}
	.el-aside{
		background: #fff;
	}
	.el-menu{
	  border: 0;	
	  
	}
	.el-main{
		padding: 0 ;
		background: #fff;
		margin-left: 20px;
	}

</style>
